import { useState, useEffect } from 'react'
import { FormControl, FormGroup, Grid } from '@mui/material'
import { LocationOn as LocationOnIcon } from '@mui/icons-material'

import BasicDialog from '../common/BasicDialog/BasicDialog'
import ProgressOverlay from '../common/ProgressOverlay'
import TextInput from '../common/TextInput'
import AutocompleteAddress from './AutocompleteAddress'

import locationService from '../../service/locationService'

import intl from '../../helper/intl'
import maps from '../../helper/maps'

import { useSelector, useDispatch } from 'react-redux'
import { registerCredentialsChange } from '../../actions/authAction'

import useStyles from './RegisterPageLocationFieldset.styles'

const RegisterPageLocationFieldset = ({ mapId }) => {
  const companyData = useSelector((state) => state.auth.companyData)

  const registerLoading = useSelector((state) => state.auth.registerLoading)
  const registerCredentials = useSelector((state) => state.auth.registerCredentials)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const [selectedMapLocation, setSelectedMapLocation] = useState(null)
  const [locationLoading, setLocationLoading] = useState(false)
  const [mapDialogIsOpen, setMapDialogIsOpen] = useState(false)
  const [fieldsAreMandatory, setFieldsAreMandatory] = useState(true)
  const [addressLabel, setAddressLabel] = useState(intl.translate('register_page__register_adressline1').replace('full', ''))
  const [currentLocation, setCurrentLocation] = useState('')

  useEffect(() => {
    if (!maps.isMapRendered(mapId)) {
      setTimeout(() => {
        maps.renderMap(mapId)
        maps.zoomToDisplayAllCoordinates(mapId, companyData.bounds)
        // if the user clicks on the map, update the location and the marker
        maps.addEventListenerToMap(mapId, 'click', (event) => {
          setLocationLoading(true)
          const lonLat = maps.toLonLat(event.coordinate)

          maps.addMarkerToMap(mapId, { longitude: lonLat[0], latitude: lonLat[1] })
          maps.center_map(mapId, lonLat[0], lonLat[1])
          locationService
            .reverseGeocode({ longitude: lonLat[0], latitude: lonLat[1] })
            .then((result) => {
              setLocationLoading(false)

              setSelectedMapLocation({
                addressLine1: result?.location?.line1
                  ? result?.location?.line1 + ' ' + result?.location?.line2
                  : intl.translate('register_page__unknown_address'),
                city: result?.location?.city || '',
                zipCode: result?.location?.postcode || '',
                state: result?.location?.state || '',
                country: result?.location?.country || '',
                longitude: lonLat[0],
                latitude: lonLat[1]
              })
            })
            .catch(() => {
              setLocationLoading(false)
              setFieldsAreMandatory(true)
            })
        })
        setAddressLabel(intl.translate('register_page__register_adressline1'))
      }, 100)
    }
  }, [])
  useEffect(() => {
    saveSelectedMapLocation()
  }, [selectedMapLocation])

  const onRegisterCredentialsChange = (paramName, paramValue) => {
    const newRegisterCredentials = { ...registerCredentials }
    newRegisterCredentials[paramName] = paramValue

    dispatch(registerCredentialsChange({ credentials: newRegisterCredentials }))
  }

  const geolocate = (data, skipFormUpdate) => {
    setLocationLoading(true)

    locationService
      .geocode(data)
      .then((result) => {
        const geocodedLocation = result.location
        if (geocodedLocation && Object.keys(geocodedLocation).length) {
          const newRegisterCredentials = {
            ...registerCredentials,
            longitude: geocodedLocation.longitude,
            latitude: geocodedLocation.latitude
          }
          maps.addMarkerToMap(mapId, { longitude: geocodedLocation.longitude, latitude: geocodedLocation.latitude })
          maps.center_map(mapId, geocodedLocation.longitude, geocodedLocation.latitude)
          if (!skipFormUpdate) {
            let line1 = geocodedLocation.line1
            if (!line1) {
              line1 = geocodedLocation.postcode || ""
              if (geocodedLocation.city) {
                line1 += (line1 ? ", " : "") + geocodedLocation.city
              }
              if (geocodedLocation.state) {
                line1 += (line1 ? ", " : "") + geocodedLocation.state
              }
              if (geocodedLocation.country) {
                line1 += (line1 ? ", " : "") + geocodedLocation.country
              }
            }
            newRegisterCredentials.addressLine1 = line1 || ''
            newRegisterCredentials.addressLine2 = geocodedLocation.line2 || ''
            newRegisterCredentials.city = geocodedLocation.city || ''
            newRegisterCredentials.zipCode = geocodedLocation.postcode || ''
            newRegisterCredentials.state = geocodedLocation.state || ''
            newRegisterCredentials.country = geocodedLocation.country || ''
          }
          dispatch(registerCredentialsChange({ credentials: newRegisterCredentials }))
        }

        setLocationLoading(false)
      })
      .catch(() => {
        setLocationLoading(false)
      })
  }

  const onLocationFieldBlur = (paramName, paramValue) => {
    if (
      !registerCredentials.addressLine1 &&
      registerCredentials.zipCode &&
      registerCredentials.state &&
      registerCredentials.country &&
      registerCredentials.city
    ) {
      const data = {
        city: registerCredentials.city,
        state: registerCredentials.state,
        country: registerCredentials.country,
        postcode: registerCredentials.zipCode
      }
      geolocate(data)
    }
  }

  const onAutocompleteLocationSelected = (autocompleteLocation) => {
    setCurrentLocation(autocompleteLocation.label)
    geolocate({ line1: autocompleteLocation.label })
  }

  const saveSelectedMapLocation = () => {
    if (selectedMapLocation) {
      let locationToDisplay = ''
      if (selectedMapLocation.addressLine1) {
        locationToDisplay += selectedMapLocation.addressLine1
        if (selectedMapLocation.addressLine2) locationToDisplay += ' ' + selectedMapLocation.addressLine2
      } else {
        locationToDisplay += intl.translate('register_page__unknown_address')
      }
      if (selectedMapLocation.city) locationToDisplay += ', ' + (selectedMapLocation.city || '')
      if (selectedMapLocation.state) locationToDisplay += ', ' + (selectedMapLocation.state || '')
      if (selectedMapLocation.zipCode) locationToDisplay += ', ' + (selectedMapLocation.zipCode || '')
      if (selectedMapLocation.country) locationToDisplay += ', ' + (selectedMapLocation.country || '')
      setCurrentLocation(locationToDisplay)

      const newRegisterCredentials = {
        ...registerCredentials,
        addressLine1: selectedMapLocation.addressLine1 + ' ' + (selectedMapLocation.addressLine2 || ''),
        city: selectedMapLocation.city || '',
        zipCode: selectedMapLocation.zipCode || '',
        state: selectedMapLocation.state || '',
        country: selectedMapLocation.country || '',
        longitude: selectedMapLocation.longitude,
        latitude: selectedMapLocation.latitude
      }
      // maps.addMarkerToMap(mapId, { longitude: selectedMapLocation.longitude, latitude: selectedMapLocation.latitude })
      setFieldsAreMandatory(!(selectedMapLocation.longitude && selectedMapLocation.latitude))
      dispatch(registerCredentialsChange({ credentials: newRegisterCredentials }))
      setSelectedMapLocation(null)
    }
  }

  return (
    <FormControl className="_position_relative_" id="register-page-location-fieldset" component="fieldset" role="group">
      <ProgressOverlay hidden={!locationLoading} />

      <FormGroup className={classes['form-group']}>
        <Grid container spacing={2} rowSpacing={8}>
          <Grid item xs={12} fontWeight={"bold"}>
            <span style={{ color: 'red' }}>
              {intl.translate('register_page__address_instructions_enter_full_address')}
            </span>
            {intl.translate('register_page__address_instructions_to_receive')}
          </Grid>
        </Grid>
        <Grid container paddingY={2} fontStyle={'italic'}>
          <Grid item xs={12}>
            {intl.translate('register_page__address_instructions_tip')}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes['address-line-1-wrapper']} alignItems="center" justifyContent="center" display="flex">
            <AutocompleteAddress
              id="register-page--address-line-1"
              value={registerCredentials.addressLine1}
              label={addressLabel}
              required={!!companyData.location_mandatory && fieldsAreMandatory}
              disabled={registerLoading}
              noOptionsText={
                !registerCredentials.addressLine1
                  ? intl.translate('register_page__start_typing')
                  : intl.translate('register_page__no_options')
              }
              onChange={(value) => {
                if (value && value.label) {
                  setAutocompleteValue(value.label)
                  onAutocompleteLocationSelected(value)
                }
              }}
              onInputChange={(value) => onRegisterCredentialsChange('addressLine1', value)}
              onFocus={() => {
                setAutocompleteValue(null)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="map-wrapper">
              <div className={classes.map} id={mapId} aria-hidden="true" role="presentation">
                <ProgressOverlay transparent={true} hidden={!locationLoading} />
              </div>
            </div>
          </Grid>

          {currentLocation && (
            <Grid item container xs={12} spacing={2} marginTop={2}>
              <Grid item xs={1} textAlign='center'>
                <LocationOnIcon />
              </Grid>
              <Grid item xs={10}>
                {currentLocation}
              </Grid>
            </Grid>
          )}

          <Grid item xs={6} hidden>
            <TextInput
              id="register-page--city"
              label={intl.translate('register_page__register_city')}
              listenToEvents
              disabled
              value={registerCredentials.city}
              onChange={(event) => onRegisterCredentialsChange('city', event.target.value)}
              isValid={(value) => !!value}
              errorMessage={intl.translate('general__empty_field_error')}
              required={!!companyData.location_mandatory && fieldsAreMandatory}
              onBlur={(event) => onLocationFieldBlur('city', event.target.value)}
            />
          </Grid>
          <Grid item xs={6} hidden>
            <TextInput
              id="register-page--zip-code"
              label={intl.translate('register_page__register_zip_code')}
              disabled
              listenToEvents
              value={registerCredentials.zipCode}
              onChange={(event) => onRegisterCredentialsChange('zipCode', event.target.value)}
              isValid={(value) => !!value}
              errorMessage={intl.translate('general__empty_field_error')}
              required={!!companyData.location_mandatory && fieldsAreMandatory}
              onBlur={(event) => onLocationFieldBlur('zipCode', event.target.value)}
            />
          </Grid>
          <Grid item xs={6} hidden>
            <TextInput
              id="register-page--state"
              label={intl.translate('register_page__register_state_province')}
              disabled
              listenToEvents
              value={registerCredentials.state}
              onChange={(event) => onRegisterCredentialsChange('state', event.target.value)}
              isValid={(value) => !!value}
              errorMessage={intl.translate('general__empty_field_error')}
              required={!!companyData.location_mandatory && fieldsAreMandatory}
              onBlur={(event) => onLocationFieldBlur('state', event.target.value)}
            />
          </Grid>
          <Grid item xs={6} hidden>
            <TextInput
              id="register-page--country"
              label={intl.translate('register_page__register_country_region')}
              disabled
              listenToEvents
              value={registerCredentials.country}
              onChange={(event) => onRegisterCredentialsChange('country', event.target.value)}
              isValid={(value) => !!value}
              errorMessage={intl.translate('general__empty_field_error')}
              required={!!companyData.location_mandatory && fieldsAreMandatory}
              onBlur={(event) => onLocationFieldBlur('country', event.target.value)}
            />
          </Grid>
        </Grid>
      </FormGroup>
      <BasicDialog
        isOpen={mapDialogIsOpen}
        title={intl.translate('register_page__select_area_title')}
        actionButtons={[
          {
            content: intl.translate('general__cancel'),
            onClick: () => {
              setMapDialogIsOpen(false)
            }
          },
          {
            variant: 'contained',
            color: 'primary',
            content: intl.translate('register_page__select_area_button'),
            showProgress: false,
            onClick: () => {
              setMapDialogIsOpen(false)
            }
          }
        ]}
        onClose={() => setMapDialogIsOpen(false)}
        maxWidth="md"
      ></BasicDialog>
    </FormControl>
  )
}

export default RegisterPageLocationFieldset
